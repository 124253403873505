

.gpt3__header-content h1 {
    font-family: var(--font-family);

    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    
    letter-spacing: -0.04em;
}






@media screen and (max-width: 1050px) {



    .gpt3__header {
        flex-direction: column;
        
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}


@media screen and (max-width: 900px) {

    .h{
   
        background-image: url('./assets/pe.jpg')  ;
        background-position: top center;
        background-repeat:no-repeat;
        background-size: cover;
        opacity: 1.0;
   
   
   
    }


}


@media screen and (min-width: 900px) {
 .h{
    width: 100%;
    height: 100vh;
    background-image: url('./assets/pe1.jpg')  ;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 0px 0px 0px 0px;/*OR padding-bottom: 10px;*/
    opacity: 1.0;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 1;




 }


 
.im{



    width: auto;
    height: 80vh;






}
}